import fetch from '@/utils/fetch';

// For /plati/com-nefacturate

export function getComosioaneNefacturate() {
    return fetch({
        url: '/commissions/getComosioaneNefacturate',
        method: 'post',
    });
}

// For /plati/com-facturate

export function getComisioaneFacturate(data) {
    return fetch({
        url: '/commissions/getComisioaneFacturate',
        method: 'post',
        data,
    });
}

export function approveOrder(data) {
    return fetch({
        url: '/commissions/approveOrder',
        method: 'post',
        data,
    });
}

export function rejectOrder(data) {
    return fetch({
        url: '/commissions/rejectOrder',
        method: 'post',
        data,
    });
}

export function generateInvoice(data) {
    return fetch({
        url: '/commissions/generateInvoice',
        method: 'post',
        data,
    });
}

export function downloadInvoice(data) {
    return fetch({
        url: '/commissions/downloadInvoice',
        method: 'post',
        data,
    });
}

export function downloadPDFBorderou(data) {
    return fetch({
        url: '/commissions/downloadPDFBorderou',
        method: 'post',
        data,
    });
}

export function downloadXLSBorderou(data) {
    return fetch({
        url: '/commissions/downloadXLSBorderou',
        method: 'post',
        data,
    });
}

export function getMonths() {
    return fetch({
        url: '/commissions/getMonths',
        method: 'post',
    });
}

export function getIntervals() {
    return fetch({
        url: '/commissions/getIntervals',
        method: 'post',
    });
}

// For /plati/plati-card

export function getCardPaymentMonths() {
    return fetch({
        url: '/commissions/getCardPaymentMonths',
        method: 'post',
    });
}

export function getCardOrdersByMonth(data) {
    return fetch({
        url: '/commissions/getCardOrdersByMonth',
        method: 'post',
        data,
    });
}

export function exportPayments(data) {
    return fetch({
        url: '/commissions/exportPayments',
        method: 'post',
        data,
    });
}

export function totalPaymentsForMonthsInterval(data) {
    return fetch({
        url: '/commissions/totalPaymentsForMonthsInterval',
        method: 'post',
        data,
    });
}

export function getPaymentsToAffiliate(data) {
    return fetch({
        url: '/commissions/getPaymentsToAffiliate',
        method: 'post',
        data,
    });
}

export function getPaymentsToCel(data) {
    return fetch({
        url: '/commissions/getPaymentsToCel',
        method: 'post',
        data,
    });
}

export function getCompensations(data) {
    return fetch({
        url: 'commissions/getCompensations',
        method: 'post',
        data,
    });
}

export function downloadCompensation(data) {
    return fetch({
        url: 'commissions/downloadCompensation',
        method: 'post',
        data,
    });
}

export function getCompensationsIntervals(data) {
    return fetch({
        url: 'commissions/getCompensationsIntervals',
        method: 'post',
        data,
    });
}

export function getStartIntervalsForInvoices() {
    return fetch({
        url: 'commissions/getStartIntervalsForInvoices',
        method: 'post',
    });
}

export function getEndIntervalsForInvoices(data) {
    return fetch({
        url: 'commissions/getEndIntervalsForInvoices',
        method: 'post',
        data,
    });
}

export function getInvoicesList(data) {
    return fetch({
        url: 'commissions/getInvoicesList',
        method: 'post',
        data,
    });
}

export function getPayments(data) {
    return fetch({
        url: 'commissions/getPayments',
        method: 'post',
        data,
    });
}
