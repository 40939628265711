<template>
	<div class='app-container'>
		<dd-card class="box-card" :title="'Filtre'" :collapsed.sync="isCollapsed"
			@toggledCard="isCollapsed = !isCollapsed">
			<el-row :gutter="10">
				<el-col :xs="24" :sm="8" :md="8" :lg="6" :xl="4">
					<div class="sub-title">Luna </div>
					<el-select size="mini" v-model="filters.month.value" @change="applyFilters">
						<el-option label="Toate" value=""></el-option>
						<el-option v-for="item in availableMonths" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
		</dd-card>
		<div class="separator"></div>
		<el-row :gutter="10">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
				<el-card class='box-card not-billed-com' v-loading="cardLoading">
					<div class="body">
						<div class="total-to-pay-incasari">
							<table class="balance">
								<thead>
									<tr>
										<th>Total de plata</th>
										<th>Platit</th>
										<th>{{statusText}}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{this.total}} RON</td>
										<td>{{this.totalPays}} RON</td>
										<td>{{parseFloat(this.total - this.totalPays).toFixed(2)}} RON</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="incasari-month-container" v-for="(m, i) in list" :key="i"
							:class="paymentBalance(m)">
							<el-collapse accordion>
								<el-collapse-item>
									<template slot="title">
										<span class="left">{{parseGroupTitle(m.month)}} | #{{m.borderou}}</span>
										<span class="right">Factura: {{m.factura}} [{{m.valoare_factura}} RON]</span>
									</template>
									<div class="payments">
										<div class="title">Plati catre CEL: {{cumulatePayments(m.plati)}} RON</div>
										<el-table size="mini" border :data="m.plati" style="width: 100%">
											<el-table-column prop="data" label="Data">
											</el-table-column>
											<el-table-column prop="suma" label="Suma">
												<template slot-scope="scope"> {{scope.row.suma}} RON</template>
											</el-table-column>
										</el-table>
									</div>

								</el-collapse-item>
							</el-collapse>
						</div>
						<div class="legenda">
							<span class="lengend-item">
								<span class="green"></span>
								<span>Platile au acoperit factura</span>
							</span>
							<span class="lengend-item">
								<span class="warning"></span>
								<span>Platile nu au acoperit in totalitate factura</span>
							</span>
							<span class="lengend-item">
								<span class="danger"></span>
								<span>Nu exista plati sau diferenta intre plata si factura este mai mare de 100
									RON</span>
							</span>
						</div>
					</div>
				</el-card>
			</el-col>
			<!-- <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
				<el-card class='box-card not-billed-com'>
					<div slot="header" class="clearfix">
						Plati neasociate
					</div>
					<div class="body">
						<el-table size="mini" border :data="neasociate" style="width: 100%">
							<el-table-column prop="data" label="Data">
							</el-table-column>
							<el-table-column prop="suma" label="Suma">
								<template slot-scope="scope"> {{scope.row.suma}} RON</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
			</el-col> -->
		</el-row>
	</div>
</template>
<script>
	const Box = () =>
		import('vue-info-box-element');
	const DDCard = () => import('@/components/DDCard');
	import capitalize from 'lodash.capitalize';
	import {
		getPaymentsToCel
	} from '@/api/plati';
	export default {
		components: {
			'dd-card': DDCard,
			Box
		},
		props: ['filterQuery'],
		data() {
			return {
				isCollapsed: true,
				filters: {
					from: {
						type: 'input',
						value: ''
					},
					to: {
						type: 'input',
						value: ''
					},
					month: {
						type: 'select',
						value: ''
					}
				},
				list: [],
				months: [],
				activeItem: '',
				total: 0,
				totalPays: 0,
				neasociate: [],
				cardLoading: true
			};
		},
		methods: {
			async getPayments() {
				let params = {};
				if (this.filters.from.value != null && this.filters.from.value !== '') {
					params.from = this.filters.from.value;
				}
				if (this.filters.to.value != null && this.filters.to.value !== '') {
					params.to = this.filters.to.value;
				}
				if (this.filters.month.value != null && this.filters.month.value !== '') {
					params = {
						month: this.filters.month.value
					};
					this.filters.from.value = '';
					this.filters.to.value = '';
				}
				try {
					const res = await getPaymentsToCel(params);
					if (res.message.facturi != null) this.list = res.message.facturi;
					if (res.message.months != null) this.months = res.message.months;
					if (res.message.total != null) this.total = res.message.total;
					if (res.message.totalPlati != null) this.totalPays = res.message.totalPlati;
					if (res.message.neasociate != null) this.neasociate = res.message.neasociate;
					this.cardLoading = false;
				} catch (err) {
					this.cardLoading = false;
				}
			},
			applyFilters() {
				if (typeof this.updateQuery === 'function') this.updateQuery();
				this.getPayments();
			},
			parseGroupTitle(date) {
				return capitalize(this.$moment(date, 'MM/YYYY').format('MMMM Y'));
			},
			cumulatePayments(payments) {
				let val = 0;
				payments = payments == null ? [] : payments;
				payments.forEach((item) => {
					val += parseFloat(item.suma);
				});
				return payments.length > 0 ? val : 0;
			},
			paymentBalance(month) {
				const paied = parseFloat(this.cumulatePayments(month.plati));
				const bill = parseFloat(month.valoare_factura);

				switch (true) {
					case month.borderou === '' || month.borderou == null || month.factura == null || parseInt(month
						.factura) === 0 ||
					parseFloat(month.suma) ==
					0:
						return '';

					case month.platit === true:
						return 'green';

					case (paied - bill < -10 && paied - bill > -100) && paied > 0:
						return 'warning';

					case month.platit === false || bill - paid > 100:
						return 'danger';

					default:
						return '';
				}
			}
		},
		computed: {
			availableMonths() {
				return this.months.map((month) => {
					return {
						label: capitalize(this.$moment(month, 'MM/YYYY').format('MMMM Y')),
						value: month
					};
				});
			},
			statusText() {
				const diff = this.total - this.totalPays;
				return diff === 0 ? 'Balanta' : (diff > 0) ? 'Ramas de plata' : 'Surplus';
			}
		},
		mounted() {
			this.getPayments();
		}
	};
</script>
<style rel='stylesheet/scss' lang='scss' scoped>
	.left {
		margin-left: 10px;
	}

	.right {
		float: right;
		margin-right: 10px;
	}

	.payments {
		padding: 10px 10px 0 10px;

		.title {
			font-size: 14px;
			color: #5e6d82;
			line-height: 1.5em;
			font-weight: bold;
			padding-bottom: 5px;
			margin-bottom: 0;
			border: 0;
		}
	}

	.total-to-pay-incasari {
		padding: 0 0 10px 0;
	}

	.balance {
		table-layout: fixed;
		border-collapse: separate;
		width: 100%;

		thead {
			color: #909399;
			font-weight: 500;
		}

		th {
			padding: 12px 0;
			min-width: 0;
			box-sizing: border-box;
			text-overflow: ellipsis;
			vertical-align: middle;
			position: relative;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			user-select: none;
			background-color: #fff;
			border-bottom: 1px solid #ebeef5;
		}

		tr {
			background-color: #fff;

			th,
			td {
				padding: 12px 0;
				min-width: 0;
				box-sizing: border-box;
				text-overflow: ellipsis;
				vertical-align: middle;
				position: relative;
				text-align: left;
			}

			td {
				transition: background-color .25s ease;
				font-size: 15px;
			}
		}
	}

	.legenda {
		font-size: 12px;
		font-weight: bold;
		margin-top: 20px;

		.lengend-item {
			padding: 0 5px;
		}

		span {
			vertical-align: middle;
			display: inline-block;
			line-height: 1;
		}

		.warning {
			height: 12px;
			width: 12px;
			background: #ff601d;
			position: relative;
		}

		.green {
			height: 12px;
			width: 12px;
			background: #67c23a;
			position: relative;
		}

		.danger {
			height: 12px;
			width: 12px;
			background: rgb(221, 97, 97);
			position: relative;
		}

	}
</style>